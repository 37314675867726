
import { defineComponent } from 'vue'
import { Form, Field } from 'vee-validate'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import Info from '@/assets/svg/info.svg?inline'
import { Vue3Lottie } from 'vue3-lottie'

export default defineComponent({
  name: 'CreateUnitStepThreeSection',
  data () {
    return {
      detailsLink: null,
      buildingId: '',
      unitId: '',
      errorUnit: null,
      unitData: {},
      isLoading: true
    }
  },
  watch: {
    detailsLink: {
      deep: true,
      handler () {
        if (this.errorUnit) {
          this.errorUnit.details_link = null
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.buildingId = this.$route.query.buildingId
    this.unitId = this.$route.query.unitId
    this.getUnit()
  },
  components: {
    AlertBlock,
    Field,
    Form,
    Info,
    LottieAnimation: Vue3Lottie
  },
  methods: {
    getUnit () {
      this.$store.dispatch('property/getUnit', this.unitId).then(
        (res) => {
          this.unitData = res.data
          this.detailsLink = this.unitData?.draft?.links?.details_link
          this.isLoading = false
        },
        (error) => {
          this.errorUnit = error.response.data.message
          this.isLoading = false
        }
      )
    },
    setPropertiesStepThree (data) {
      data = {
        unitId: this.unitId,
        details_link: data.detailsLink
      }
      this.$store.dispatch('property/setPropertiesStepThree', data).then(
        () => {
          this.$router.push(`/dashboard/create-unit-step-four?buildingId=${this.buildingId}&unitId=${this.unitId}`)
        },
        (error) => {
          this.errorUnit = error.response.data.errors
        }
      )
    },
    goToSecondStep () {
      this.$router.push(`/dashboard/create-unit-step-two?buildingId=${this.buildingId}&unitId=${this.unitId}`)
    }
  }
})
